<template>
  <div id="app">

    <!-- 头部容器 -->
    <el-container>
      <!-- 头部导航栏 -->
      <el-header class="head_navhead" :height="!isMobile ? '100px' : '60px'">
        <!-- 头部导航栏内容 -->
        <el-row :gutter="20">
          <!-- 左侧LOGO和标题 -->
          <el-col :span="!isMobile ? 8 : 20" style="text-align: left; display: flex; align-items: center;">
            <img class="head_logo" src="./assets/logo.png" />
            <h1 style="margin-left: 30px;" v-if="!isMobile">互联科创会员中心</h1>
            <h3 style="margin-left: 15px;" v-if="isMobile">互联科创会员中心</h3>
          </el-col>
          <!-- 右侧头部导航和用户信息 -->
          <el-col class="head_nav" :span="!isMobile ? 16 : 4">
            <!-- 大屏幕下的头部导航 -->
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" v-if="!isMobile" :router="true">
              <el-menu-item v-for="(item, index) in headNavList" :key="index" :index="item.url">{{ item.title
              }}</el-menu-item>
              <el-menu-item>
                <el-button type="primary" v-if="userName == ''">登录</el-button>
                <el-dropdown v-if="userName != ''" @command="handleUserMenuCommand">
                  <!-- 用户头像和下拉菜单 -->
                  <span class="el-dropdown-link">
                    <el-image
                      style="width: 40px; height: 40px; border-radius: 100%; border: #96969630 0.5px solid; padding: 3px;"
                      :src="profilePhotos"></el-image>
                  </span>
                  <el-dropdown-menu slot="dropdown" style="width: 200px;">
                    <!-- 用户信息展示 -->
                    <div style="padding-left: 20px; font-size: 0.8rem">
                      <p>{{ userName }}</p>
                      <p><strong>{{ userDepartment }}</strong> {{ userPosition }}</p>
                    </div>
                    <el-dropdown-item icon="el-icon-error" command="exitlogin">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-menu-item>
            </el-menu>

            <!-- 小屏幕下的头部导航和用户信息 -->
            <el-dropdown v-if="isMobile" @command="handleHeadMobileMenuCommand">
              <el-button icon="el-icon-menu" style="margin-top: 5px;"></el-button>
              <el-dropdown-menu slot="dropdown" style="width: 150px;">
                <el-dropdown-item v-for="(item, index) in headNavList" :key="index" :command="item.index">{{ item.title
                }}</el-dropdown-item>
                <el-divider></el-divider>
                <!-- 用户信息展示 -->
                <div style="padding-left: 20px; font-size: 0.8rem">
                  <el-image
                    style="width: 50px; height: 50px; border-radius: 100%; border: #96969630 0.5px solid; padding: 3px;"
                    :src="profilePhotos"></el-image>
                  <p>{{ userName }}</p>
                  <p><strong>{{ userDepartment }}</strong> {{ userPosition }}</p>
                </div>
                <el-dropdown-item icon="el-icon-error" command="exitlogin">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </el-header>

      <!-- 主体容器 -->
      <el-container>
        <!-- 左侧导航栏 -->
        <el-aside :width="!isMobile || !isCollapse ? '200px' : '65px'" class="left_nav" v-if="needLeftNav">
          <el-menu class="el-menu-vertical-demo" :collapse="isCollapse" :default-active="leftActiveIndex"
            style="text-align: left;" :router="true">
            <!-- 左侧导航菜单项 -->
            <el-menu-item :index="item.url" v-for="(item, index) in leftNavList" :key="index" v-if="item.power <= power">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
            <!-- 移动端折叠/展开按钮 -->
            <el-menu-item @click="isCollapse = !isCollapse" v-if="isMobile">
              <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
            </el-menu-item>
          </el-menu>
        </el-aside>

        <!-- 主体内容区域 -->
        <el-main :class="needLeftNav ? 'main_box' : 'main_box_noleft'">
          <!-- 路由视图 -->
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script>
import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios';
import request from '@/utils/request'
import Cookies from 'js-cookie'
import { bus } from '@/main.js';
import { getUserInfo } from '@/utils/auth'

var departmentUrls = {
  理事会: '/council',
  技术部: '/td',
  编程部: '/pd',
  运营部: '/od',
  双创部: '/ied',
  电子部: '/ed',
  数学协会: '/ma'
}



export default {
  name: 'app',
  data() {
    return {
      isMobile: false,
      activeIndex: "",
      isCollapse: true,
      leftActiveIndex: "0",
      needLeftNav: true,

      leftNavList: null,
      headNavList: [
        {
          title: '首页',
          index: '0',
          url: '/'
        },
        {
          title: '我的部门',
          index: '1',
          url: ''
        },
        // {
        //   title: '个人中心',
        //   index: '2',
        //   url: '/our'
        // },
      ],

      // 登录后信息
      profilePhotos: '',
      userName: '',
      userDepartment: '',
      userPosition: '',

      power: 0

    }
  },
  components: {
    HelloWorld
  },
  created() {
    this.login()

    bus.$on('setLeftNav', data => {
      this.needLeftNav = data.showNav;
      this.leftNavList = data.nav
    });

    this.isMobile = window.innerWidth <= 800;
    this.isCollapse = this.isMobile
    setInterval(() => {
      this.isMobile = window.innerWidth <= 800;
    }, 100);
  },

  updated() {
    this.login()
    this.getpower()
  },
  methods: {
    handleSelect(index) {
      this.$router.push(this.headNavList[Number(index)].url)
    },
    leftSelect(index) {
      if (index == null) {
        return
      }
      this.$router.push(this.leftNavList[Number(index)].url)
    },
    handleHeadMobileMenuCommand(command) {
      if (command == 'exitlogin') {
        Cookies.set('token', ''); location.reload();
        return
      }
      this.handleSelect(command)
    },
    login() {
      const params = new URLSearchParams(window.location.search)
      const token = params.get('token')

      if (token) {
        axios.post('https://authapi.gcacitia.com/access/verify-token', {
          appid: 'qiVEZitFRlsfr2wMBf5C1UBUYm9B6V9z',
          token: token
        })
          .then(response => {
            Cookies.set('token', token)
            localStorage.setItem('name', response.data.username)
            localStorage.setItem('department', response.data.department)
            localStorage.setItem('position', response.data.position)
            localStorage.setItem('studentid', response.data.studentid)
            localStorage.setItem('profilePhotos', response.data.profile_photo_url)
            localStorage.setItem('power', response.data.power)

            this.userName = localStorage.getItem('name')
            this.userDepartment = localStorage.getItem('department')
            this.userPosition = localStorage.getItem('position')
            this.profilePhotos = localStorage.getItem('profilePhotos')

            this.headNavList[1].url = departmentUrls[this.userDepartment]

            this.$router.push('/')
          })
          .catch(error => {
            Cookies.set('token', '')
            const currentUrl = encodeURIComponent(window.location.href) // Get the current page URL
            window.location.href = `https://auth.gcacitia.com/?appid=qiVEZitFRlsfr2wMBf5C1UBUYm9B6V9z&callback_url=${currentUrl}`
            return
          });
      } else {
        if (Cookies.get('token') == null) {
          const currentUrl = encodeURIComponent(window.location.href) // Get the current page URL
          window.location.href = `https://auth.gcacitia.com/?appid=qiVEZitFRlsfr2wMBf5C1UBUYm9B6V9z&callback_url=${currentUrl}`
        } else {
          axios.post('https://authapi.gcacitia.com/access/verify-token', {
            appid: 'qiVEZitFRlsfr2wMBf5C1UBUYm9B6V9z',
            token: Cookies.get('token')
          })
            .then(response => {
              this.userName = localStorage.getItem('name')
              this.userDepartment = localStorage.getItem('department')
              this.userPosition = localStorage.getItem('position')
              this.profilePhotos = localStorage.getItem('profilePhotos')

              this.headNavList[1].url = departmentUrls[this.userDepartment]
            })
            .catch(error => {
              const currentUrl = encodeURIComponent(window.location.href) // Get the current page URL
              window.location.href = `https://auth.gcacitia.com/?appid=qiVEZitFRlsfr2wMBf5C1UBUYm9B6V9z&callback_url=${currentUrl}`
              return
            });
        }
      }


    },

    handleUserMenuCommand(command) {
      if (command == 'exitlogin') {
        Cookies.set('token', ''); location.reload();
      }
    },

    getpower() {
      this.power = getUserInfo().power
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.el-menu {
  border: 0 !important;
}

.head_navhead {
  height: 100px;
  position: relative;
}

.left_nav {
  display: block;
  position: absolute;
  left: 0;
  top: 120px;
  bottom: 0;
  z-index: 1000;
}

.head_logo {
  width: 100px;
  height: 100px;
}

.head_nav {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 100px;
}

.main_box {
  position: absolute;
  left: 200px;
  right: 0;
  top: 120px;
  bottom: 0;
  overflow-y: scroll;
}

.main_box_noleft {
  position: absolute;
  left: 0;
  right: 0;
  top: 120px;
  bottom: 0;
  overflow-y: scroll;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .head_navhead {
    height: 60px;
  }

  .head_logo {
    width: 60px;
    height: 60px;
  }

  .head_nav {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 60px;
  }

  .left_nav {
    display: block;
    position: absolute;
    left: 0;
    top: 80px;
    bottom: 0;
  }

  .main_box {
    position: absolute;
    left: 65px;
    right: 0;
    top: 80px;
    bottom: 0;
    overflow-y: scroll;
  }

  .main_box_noleft {
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    bottom: 0;
    overflow-y: scroll;
  }
}
</style>
