<template>
  <div>
    <h1>{{ userInfo.name }} 同学，欢迎加入互联网科技创新协会</h1>
    <el-divider></el-divider>
    <el-row :gutter="12">
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>会员信息</span>
          </div>
          <div>
            <el-row :gutter="12">
              <el-col :span="16">
                <el-descriptions :column="1">
                  <el-descriptions-item label="姓名">{{ userInfo.name }}</el-descriptions-item>
                  <el-descriptions-item label="学号">{{ userInfo.studentid }}</el-descriptions-item>
                  <el-descriptions-item label="部门">{{ userInfo.department }}</el-descriptions-item>
                  <el-descriptions-item label="岗位">{{ userInfo.position }}</el-descriptions-item>
                </el-descriptions>
              </el-col>

              <el-col :span="8">
                <el-image style="width: 50%; border-radius: 100%; padding: 5px;" :src="userInfo.profilePhotos"></el-image>
              </el-col>
            </el-row>

          </div>
        </el-card>
      </el-col>

      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
        <!-- <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>活动活跃度</span>
          </div>
          <div>
            <el-row :gutter="12">
              <el-col :span="16">
                <el-descriptions :column="1">
                  <el-descriptions-item label="姓名">{{ userInfo.name }}</el-descriptions-item>
                  <el-descriptions-item label="学号">{{ userInfo.studentid }}</el-descriptions-item>
                  <el-descriptions-item label="部门">{{ userInfo.department }}</el-descriptions-item>
                  <el-descriptions-item label="岗位">{{ userInfo.position }}</el-descriptions-item>
                </el-descriptions>
              </el-col>

              <el-col :span="8">
                <el-image style="width: 50%; border-radius: 100%; padding: 5px;" :src="userInfo.profilePhotos"></el-image>
              </el-col>
            </el-row>

          </div>
        </el-card> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { getUserInfo } from '@/utils/auth'
import axios from 'axios';
import { getToken } from '@/utils/auth'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      userInfo: null
    }
  },
  created() {

    this.$setLeftNav(true, [
      {
        title: '我的',
        icon: 'el-icon-menu',
        url: '/',
        power: 0
      },
      {
        title: '资源下载',
        icon: 'el-icon-s-help',
        url: '/downloadsite',
        power: 0
      },
      {
        title: '招新面试',
        icon: 'el-icon-s-help',
        url: '/ms',
        power: 2
      },
      {
        title: '招新录取',
        icon: 'el-icon-s-help',
        url: '/lq',
        power: 2
      },
      {
        title: '录取结果',
        icon: 'el-icon-s-help',
        url: '/lqjg',
        power: 2
      }
    ])
  },

  mounted() {
    setTimeout(() => {
      this.userInfo = getUserInfo()
    }, 100);

  }
}
</script>

<style scoped>
.el-card {
  height: 230px;
}
</style>