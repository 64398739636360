import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '首页 - 会员中心' }
  },
  {
    path: '/downloadsite',
    name: 'downloadsite',
    component: () => import('../views/资源下载.vue'),
    meta: { title: '资源下载 - 会员中心' }
  },
  {
    path: '/ms',
    name: 'ms',
    component: () => import('../views/招新面试.vue'),
    meta: { title: '招新面试 - 会员中心' }
  },
  {
    path: '/lq',
    name: 'lq',
    component: () => import('../views/招新录取.vue'),
    meta: { title: '招新录取 - 会员中心' }
  },
  {
    path: '/lqjg',
    name: 'lqjg',
    component: () => import('../views/录取结果.vue'),
    meta: { title: '录取结果 - 会员中心' }
  },

  {
    path: '/council',
    name: 'council',
    component: () => import('../views/department/理事会.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/department/departmentSubs/理事会/首页.vue'),
        meta: { title: '首页 - 理事会' }
      },
      {
        path: 'otherdepartment',
        component: () => import('../views/department/departmentSubs/理事会/其他部门.vue'),
        meta: { title: '其他部门 - 理事会' }
      },
      {
        path: 'activation_manage',
        component: () => import('../views/department/publicSubs/活跃度管理.vue'),
        meta: { title: '活跃度管理 - 理事会' }
      },
      {
        path: 'downloadsite_manage',
        component: () => import('../views/department/departmentSubs/理事会/资源下载管理.vue'),
        meta: { title: '下载站管理 - 理事会' }
      },
      {
        path: 'members_manage',
        component: () => import('../views/department/publicSubs/会员管理.vue'),
        meta: { title: '会员管理 - 理事会' }
      },
      {
        path: 'applicationinfo',
        component: () => import('../views/department/publicSubs/招新报名详情.vue'),
        meta: { title: '招新报名 - 理事会' }
      }
    ]
  },
  {
    path: '/td',
    name: 'technology_department',
    component: () => import('../views/department/技术部.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/department/departmentSubs/技术部/首页.vue'),
        meta: { title: '首页 - 技术部' }
      },
      {
        path: 'repair',
        component: () => import('../views/department/departmentSubs/技术部/报修处理.vue'),
        meta: { title: '报修处理 - 技术部' }
      },
      {
        path: 'activation_manage',
        component: () => import('../views/department/publicSubs/活跃度管理.vue'),
        meta: { title: '活跃度管理 - 技术部' }
      },
      {
        path: 'members_manage',
        component: () => import('../views/department/publicSubs/会员管理.vue'),
        meta: { title: '会员管理 - 技术部' }
      },
      {
        path: 'applicationinfo',
        component: () => import('../views/department/publicSubs/招新报名详情.vue'),
        meta: { title: '招新报名 - 技术部' }
      }
    ]
  },
  {
    path: '/od',
    name: 'od',
    component: () => import('../views/department/运营部.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/department/departmentSubs/运营部/首页.vue'),
        meta: { title: '首页 - 运营部' }
      },
      {
        path: 'activation_manage',
        component: () => import('../views/department/publicSubs/活跃度管理.vue'),
        meta: { title: '活跃度管理 - 运营部' }
      },
      {
        path: 'members_manage',
        component: () => import('../views/department/publicSubs/会员管理.vue'),
        meta: { title: '会员管理 - 运营部' }
      },
      {
        path: 'applicationinfo',
        component: () => import('../views/department/publicSubs/招新报名详情.vue'),
        meta: { title: '招新报名 - 运营部' }
      }
    ]
  },
  {
    path: '/pd',
    name: 'pd',
    component: () => import('../views/department/编程部.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/department/departmentSubs/编程部/首页.vue'),
        meta: { title: '首页 - 编程部' }
      },
      {
        path: 'activation_manage',
        component: () => import('../views/department/publicSubs/活跃度管理.vue'),
        meta: { title: '活跃度管理 - 编程部' }
      },
      {
        path: 'members_manage',
        component: () => import('../views/department/publicSubs/会员管理.vue'),
        meta: { title: '会员管理 - 编程部' }
      },
      {
        path: 'applicationinfo',
        component: () => import('../views/department/publicSubs/招新报名详情.vue'),
        meta: { title: '招新报名 - 编程部' }
      }
    ]
  },
  {
    path: '/ied',
    name: 'ied',
    component: () => import('../views/department/双创部.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/department/departmentSubs/双创部/首页.vue'),
        meta: { title: '首页 - 双创部' }
      },
      {
        path: 'activation_manage',
        component: () => import('../views/department/publicSubs/活跃度管理.vue'),
        meta: { title: '活跃度管理 - 双创部' }
      },
      {
        path: 'members_manage',
        component: () => import('../views/department/publicSubs/会员管理.vue'),
        meta: { title: '会员管理 - 双创部' }
      },
      {
        path: 'applicationinfo',
        component: () => import('../views/department/publicSubs/招新报名详情.vue'),
        meta: { title: '招新报名 - 双创部' }
      }
    ]
  },
  {
    path: '/ed',
    name: 'ed',
    component: () => import('../views/department/电子部.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/department/departmentSubs/电子部/首页.vue'),
        meta: { title: '首页 - 电子部' }
      },
      {
        path: 'activation_manage',
        component: () => import('../views/department/publicSubs/活跃度管理.vue'),
        meta: { title: '活跃度管理 - 电子部' }
      },
      {
        path: 'members_manage',
        component: () => import('../views/department/publicSubs/会员管理.vue'),
        meta: { title: '会员管理 - 电子部' }
      },
      {
        path: 'applicationinfo',
        component: () => import('../views/department/publicSubs/招新报名详情.vue'),
        meta: { title: '招新报名 - 电子部' }
      }
    ]
  },
  {
    path: '/ma',
    name: 'ma',
    component: () => import('../views/department/数学协会.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/department/departmentSubs/数学协会/首页.vue'),
        meta: { title: '首页 - 数学协会' }
      },
      {
        path: 'applicationinfo',
        component: () => import('../views/department/departmentSubs/数学协会/招新报名详情.vue'),
        meta: { title: '招新报名 - 数学协会' }
      }
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})


export default router
