import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'


Vue.config.productionTip = false

export const bus = new Vue();
import { setLeftNav } from '@/utils/setLeftNav.js';
Vue.prototype.$setLeftNav = setLeftNav;

import { getUserInfo } from './utils/auth'
Vue.prototype.$getUserInfo = getUserInfo;

import isMobile from '@/utils/isMobile';
Vue.use(isMobile);

import request from '@/utils/request'
Vue.prototype.$request = request;

import { Notification, MessageBox, Message, Loading } from 'element-ui'
Vue.prototype.$Message = Message;


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

